import Vue from 'vue'
import Vuex from 'vuex'

if (!window.Vuex) {
  //不是用CDN引入的
  Vue.use(Vuex);
}

import banner from './banner';
import nav from './nav';
import loginUser from './login';
import everyDay from './everyday';

// const devURL = 'http://localhost:9528';
// const proURL = 'http://www.clei.top:9528';

const URL = process.env.VUE_APP_BASE_API;

export default new Vuex.Store({
  state: {
    // baseURL: 'http://localhost:9528/api/download/',
    baseURL: `${URL}/api/download/`,
    uploadURL: `${URL}/api/upload`,
    headers: {
      Authorization: localStorage.getItem('token'),
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    banner,
    nav,
    loginUser,
    everyDay
  },
  strict: true
})
