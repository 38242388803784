<!--
 * @Author: CL
 * @Date: 2021-06-02 13:42:26
 * @LastEditTime: 2021-07-27 17:22:59
 * @Description: 图标组件
 * 1. type: 图标的类型, 必传
 *    利用一个映射表来自己掌握图标的命名
 * 2. color: 图标的颜色, 可传
 * 3. size: 尺寸, 宽高相同, 可传, 有默认值，16 单位px
-->

<template>
  <i class="iconfont icon-i" :class="iconType" :style="{ color: color, fontSize: size + 'px' }"></i>
</template>

<script>
//一个图标类型的映射表
const Mapping = {
  home: "icon-shouye",   //首页
  message: "icon-jurassic_message",  //留言
  message2: 'icon-b-chat', 
  code: 'icon-daima',   //代码
  about: 'icon-guanyuwomen',   //关于我
  about2: 'icon-guanyuwomen1',
  blog: 'icon-wenzhang',       //文章
  blog2: 'icon-16',
  blog3: 'icon-wenzhang1',
  classify: "icon-leimupinleifenleileibie",  //分类
  leftArrow: "icon-zuojiantou",  //左箭头
  rightArrow: "icon-youjiantou", //右箭头
  topArrow: 'icon-xiangshang',   //向上的箭头
  downArrow: 'icon-xiangxia',    //向下的箭头
  qq: 'icon-qq',   //qq
  wechat: 'icon-weixin-copy',  //微信
  email: 'icon-youxiang1',     //邮箱
  github: 'icon-GitHub',       //github
  success: 'icon-chenggong',   //成功
  error: 'icon-cuowu',         //错误
  info: 'icon-info',           //信息
  warning: 'icon-jinggao',     //警告
  like: 'icon-xihuan',         //喜欢
  views: 'icon-liulan1',       //浏览
  commen: 'icon-pinglun',      //评论
  time: 'icon-shijian1',       //创建时间
  img: 'icon-tupian',          //图片 
  login: 'icon-xiugaitouxiang',//登录
  manage: 'icon-houtaiguanli', //后台管理 
  logout: 'icon-tuichudenglu', //注销 
  nav: 'icon-daohang',         //导航
  everyday: 'icon-wenzi',      //每日一句
  tag: 'icon-biaoqian2',        //标签
  play: 'icon-tubiaozhizuosvg-10',  //休闲娱乐
}

export default {
  props: {
    type: {
      type: String,
      required: true
    },

    color: {
      type: String
    },

    size: {
      type: Number,
      default: 16
    }
  },

  computed: {
    /**
     * 图标类型计算属性
     */
    iconType(){
      return Mapping[this.type];
    }
  }
}
</script>

<style scoped>
@import url('//at.alicdn.com/t/font_2586356_doimeduav9m.css');

.icon-i{
  display: block;
}
</style>