/*
 * @Author: CL
 * @Date: 2021-06-23 23:06:26
 * @LastEditTime: 2021-08-03 16:57:08
 * @Description: 
 */

import { MessageBox } from 'element-ui';

/**
 * 确认和取消弹出框
 * @param {*} content 内容 
 * @param {*} title 标题
 * @param {*} params 配置参数
 * @returns 
 */
const confirm = function (content, title = '标题', params) {
  return MessageBox.confirm(content, title, {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    ...params
  })
}

/**
 * 深客隆
 * @param {*} source 
 * @returns 
 */
const deepClone = function (source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * 延迟函数
 * @param {*} timeout 
 * @returns 
 */
const delay = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  })
}

/**
 * 防抖函数
 * @param {*} duration 
 * 原理，就是当多次调用时，每调用一次定时器都会重新计算，只到你不触发函数了，定时器开始计算
 * 直到设置的事件，调用函数
 */
const deBounce = (fn, duration = 500) => {
  let timer = null;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, duration)
  }
}

let routerTitle = '';
let siteTitle = '';

/**
 * 统一设置网站标题
 * @param {*} title 
 */
const setTitle = () => {
  if (!routerTitle && !siteTitle) {
    document.title = '加载中...';
  } else if (routerTitle && !siteTitle) {
    document.title = routerTitle;
  } else if (!routerTitle && siteTitle) {
    document.title = siteTitle;
  }
}

const setRouterTitle = (title) => {
  routerTitle = title;
  setTitle();
}

const setSiteTitle = (title) => {
  siteTitle = title;
  setTitle();
}


export default {
  confirm,
  deepClone,
  delay,
  deBounce,
  setRouterTitle,
  setSiteTitle
}