/*
 * @Author: CL
 * @Date: 2021-06-21 22:32:21
 * @LastEditTime: 2021-06-28 10:21:49
 * @Description: 轮播数据
 * 1. 一般轮播数据都是固定的，不太会有大的变动，所以保存在仓库中
 */

import { getBanner } from '@/api/banner.js';
import { delay } from '@/utils';

export default {
  namespaced: true,
  state: {
    bannerList: [],    //轮播数据
    loading: true,
  },

  mutations: {
    setBannerList(state, payload) {
      state.bannerList = payload;
    },

    setLoading(state, payload) {
      state.loading = payload;
    }
  },

  actions: {
    /**
     * 查询轮播数据
     */
    async queryBanner({ commit, state }) {
      commit('setLoading', true);
      //如果数组不为空，或者正在加载中，不重新请求
      if (state.bannerList.length !== 0) {
        commit('setLoading', false);
        return;
      } 
      const res = await getBanner(1, 10);
      commit('setLoading', false);
      if (res.code == 200) {
        commit('setBannerList', res.data.rows);
      }
    }
  }
}