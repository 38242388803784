/*
 * @Author: CL
 * @Date: 2021-06-14 15:38:38
 * @LastEditTime: 2021-06-21 23:13:05
 * @Description: v-loading 自定义指令
 * 
 * 1. 先判断页面中有没有这个loading图片, 如果有并且是要展示的，就什么都不做，
 *    如果是要隐藏，那么就删除掉这个图片，
 * 
 * 2. 如果页面没有这个图片，那么就添加图片
 */

import loadingImg from '../assets/loading.svg';
import style from './loading.module.less';

export default function (el, binding) {
  const img = el.querySelector('img[data-img="loading"]');
  const { value } = binding;
  if (img && !value) {
    //如果图片存在并且，value为false，就要移除图片
    img.remove();
  } else if (!img && value) {
    //图片不存在，但是要显示图片，那么就要加一张图片
    const img = addImg();
    el.appendChild(img);
  }
}

function addImg() {
  const img = document.createElement('img');
  img.dataset.img = 'loading';
  img.src = loadingImg;
  img.className = style['loading'];
  return img;
}