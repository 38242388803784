/*
 * @Author: CL
 * @Date: 2021-06-21 10:44:12
 * @LastEditTime: 2021-07-28 10:38:21
 * @Description: 路由配置
 */

import { getBlogDetail } from '@/api/blog';

export default [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home'),
    meta: {
      title: '淡写轻描'
    }
  },
  //武功秘籍
  {
    path: '/article',
    name: 'article',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article'),
    meta: {
      title: '武林秘籍'
    }
  },
  //文章详情页,动态路由
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/Detail'),
    beforeEnter: async (to, from, next) => {
      const { id } = to.params;
      const res = await getBlogDetail(id);
      if(res.code == 404){
        //如果这篇文章不存在的话，跳转到404
        next({ name: 'notfind' })
      }
      next();
    }
  },
  //图片鉴赏
  {
    path: '/img',
    name: 'img',
    component: () => import(/* webpackChunkName: "img" */ '../views/Img'),
    meta: {
      title: '图片鉴赏'
    }
  },
  //故事与酒
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About'),
    meta: {
      title: '故事与酒'
    }
  },
  //代码效果
  {
    path: '/code',
    name: 'code',
    component: () => import(/* webpackChunkName: "code" */ '../views/Code'),
    meta: {
      title: '代码&效果'
    }
  },
  //对我说
  {
    path: '/message',
    name: 'message',
    component: () => import(/* webpackChunkName: "message" */ '../views/Message'),
    meta: {
      title: '对我说'
    }
  },
  //放松一下
  {
    path: '/relax',
    name: 'relax',
    component: () => import(/* webpackChunkName: "relax" */ '../views/Relax'),
    meta: {
      title: '放松一下'
    }
  },
  //游戏
  {
    path: '/games',
    name: 'games',
    component: () => import(/* webpackChunkName: "games" */ '../views/Games'),
    meta: {
      title: '游戏'
    },
    beforeEnter: (to, from, next) => {
      toRelax(to, from, next);
    }
  },
  {
    path: '/music',
    name: 'music',
    component: () => import(/* webpackChunkName: "music" */ '../views/Music'),
    meta: {
      title: '音乐'
    },
    beforeEnter: (to, from, next) => {
      toRelax(to, from, next);
    }
  },
  //登录页
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
    meta: {
      title: '登录'
    }
  },
  //后台管理首页
  {
    path: '/manage',
    name: 'manage',
    component: () => import(/* webpackChunkName: "manage" */ '../views/Manage'),
    meta: {
      auth: true,     //标志着这个页面需要权限才能访问
    },
    redirect: '/manage/articleback',
    children: [
      //文章后台管理页面
      {
        path: 'articleback',
        name: 'articleback',
        component: () => import(/* webpackChunkName: "articleback" */ '../views/ArticleBack'),
        meta: { auth: true, title: '文章管理' }
      },
      //账号管理页面
      {
        path: 'adminback',
        name: 'adminback',
        component: () => import(/* webpackChunkName: "adminback" */ '../views/AdminBack'),
        meta: { auth: true, title: '账号管理' }
      },
      //图片鉴赏后台管理
      {
        path: 'imgback',
        name: 'imgback',
        component: () => import(/* webpackChunkName: "imgback" */ '../views/ImgBack'),
        meta: { auth: true, title: '图片鉴赏管理' }
      },
      //功能导航
      {
        path: 'navback',
        name: 'navback',
        component: () => import(/* webpackChunkName: "navback" */ '../views/NavBack'),
        meta: { auth: true, title: '导航管理' }
      },
      //代码效果
      {
        path: 'codeback',
        name: 'codeback',
        component: () => import(/* webpackChunkName: "codeback" */ '../views/CodeBack'),
        meta: { auth: true, title: '代码管理' }
      },
      //留言管理
      {
        path: 'messageback',
        name: 'messageback',
        component: () => import(/* webpackChunkName: "messageback" */ '../views/MessageBack'),
        meta: { auth: true, title: '留言管理' }
      },
      //每日一句
      {
        path: 'everyday',
        name: 'everydayback',
        component: () => import(/* webpackChunkName: "everydayback" */ '../views/EveryDayBack'),
        meta: { auth: true, title: '每日一句' }
      }
    ]
  },
  {
    path: '*',
    name: 'notfind',
    component: () => import(/* webpackChunkName: "notfind" */ '../views/NotFind')
  }
]


function toRelax(to, from, next){
  const { path } = from;
  if(path != '/relax'){
    next({ name: 'relax' });
  }
  next();
}
