/*
 * @Author: CL
 * @Date: 2021-06-25 22:38:38
 * @LastEditTime: 2021-06-25 23:55:53
 * @Description: 每日一句的相关api
 */

import request from './request';

/**
 * 添加每日一句
 * @param {*} data 
 * @returns 
 */
export function addEveryDay(data) {
  return request({
    url: '/api/everyday',
    method: 'post',
    data
  })
}

/**
 * 根据id来修改数据
 */
export function editEveryDay(id, data = {}) {
  return request({
    url: `/api/everyday/${id}`,
    method: 'put',
    data
  })
}

/**
 * 查询最新的每日一句
 */
export function queryWord() {
  return request({
    url: '/api/everyday/thelast',
    method: 'get'
  })
}

/**
 * 分页查询
 */
export function queryByPage(page = 1, size = 10, data = {}) {
  return request({
    url: `/api/everyday/${page}/${size}`,
    method: 'get',
    params: data
  })
}

/**
 * 根据id来删除数据
 */
export function delEveryDay(id) {
  return request({
    url: `/api/everyday/${id}`,
    method: 'delete'
  })
}