/*
 * @Author: CL
 * @Date: 2021-06-28 13:48:11
 * @LastEditTime: 2021-06-28 15:10:56
 * @Description: 图片懒加载自定义指令
 */

import defpic from '@/assets/default.gif';
import EventBus from '@/EventBus';
import utils from '@/utils/utils.js';

/**
 * 记录要处理图片的数组
 */
let arr = [];

export default {
  //绑定元素被插入父节点时调用
  inserted(el, bindings) {
    const temp = {
      dom: el,
      src: bindings.value
    }

    //一开始就把要处理的图片加入到数组中
    arr.push(temp);

    //立即处理这个图片
    setImage(temp);
  },

  //指令与元素解绑时调用
  unbind(el) {
    arr = arr.filter((img) => img.dom !== el);
  }
}

/**
 * 处理单个图片
 * @param {*} data 
 */
function setImage(data) {
  //先让这个图片使用默认的图片
  data.dom.src = defpic;

  //看这个图片是否在可视范围内
  const clientHeight = document.documentElement.clientHeight;
  const rect = data.dom.getBoundingClientRect();
  const height = rect.height || 150;
  if (rect.top <= clientHeight && rect.top >= -height) {
    //在可视范围内的，立即替换成正式的图片路径
    data.dom.src = data.src;
    //这个加载完成后就要从数组中去掉
    arr = arr.filter(i => i !== data);
  }
}

function setImages() {
  for (const img of arr) {
    setImage(img);
  }
}

//监听mainScroll事件
EventBus.$on('mainScroll', utils.deBounce(handleScroll, 500));

function handleScroll() {
  setImages();
}
