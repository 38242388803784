import Vue from 'vue'
import VueRouter from 'vue-router'

if (!window.VueRouter) {
  //如果不存在就不是用CDN的方式引入的
  Vue.use(VueRouter);
}

import routes from './routes';
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import utils from '@/utils/utils';

Nprogress.configure({
  showSpinner: false
})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * 路由跳转之前的钩子函数
 */
router.beforeEach((to, from, next) => {
  Nprogress.start();
  const user = JSON.parse(localStorage.getItem('user'));

  //如果已经登陆过是不需要跳转到登录页的
  if (user && to.path === '/login') {
    Vue.prototype.$showMessage({
      message: '您已经登录了哦！',
      type: 'warning',
      duration: 3000,
    });
    next({
      name: 'home'
    })
    return;
  }

  if (to.meta.auth) {
    //需要登录过后才能访问
    if (user) {
      //登录过
      next();
    } else {
      //没有登录过，跳转到登录页
      next({
        name: 'login',
        query: {
          returnurl: to.fullPath
        }
      })
    }
    return;
  }

  next();
})

/**
 * 路由跳转之后的钩子函数
 */
router.afterEach((to, from) => {
  Nprogress.done();

  if (to.meta.title) {
    utils.setRouterTitle(to.meta.title);
  }
})

export default router
