/*
 * @Author: CL
 * @Date: 2021-06-22 09:41:46
 * @LastEditTime: 2021-06-22 09:52:43
 * @Description: 导航数据
 */

import { queryNav } from "../api/nav"

export default {
  namespaced: true,
  state: {
    loading: true,
    navList: []
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },

    setNavList(state, payload) {
      state.navList = payload;
    }
  },

  actions: {
    /**
     * 查询所有的导航数据 
     */
    async queryNav({ commit, state }) {
      if (state.navList.length !== 0) return;
      commit('setLoading', true);
      const res = await queryNav();
      commit('setLoading', false);
      if (res.code == 200) {
        commit('setNavList', res.data);
      }
    }
  }
}
