/*
 * @Author: CL
 * @Date: 2021-06-28 14:25:22
 * @LastEditTime: 2021-06-28 14:29:44
 * @Description: 事件总线
 */

import Vue from 'vue';

const app = new Vue({});

Vue.prototype.$bus = app;

export default app;
