/*
 * @Author: CL
 * @Date: 2021-06-21 17:15:58
 * @LastEditTime: 2021-06-28 11:00:34
 * @Description: 图片鉴赏相关api
 */

import request from './request';

/**
 * 得到图片鉴赏的轮播数据
 */
export function getBanner(page, size) {
  return request({
    url: `/api/banner/${page}/${size}`,
    method: 'get'
  })
}

/**
 * 添加数据
 */
export function addImg(data) {
  return request({
    url: `/api/banner`,
    method: 'post',
    data
  })
}

/**
 * 删除图片鉴赏的数据
 */
export function delImg(id) {
  return request({
    url: `/api/banner/${id}`,
    method: 'delete'
  })
}

/**
 * 修改数据
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export function editImg(id, data) {
  return request({
    url: `/api/banner/${id}`,
    method: 'put',
    data
  })
}
