/*
 * @Author: CL
 * @Date: 2021-05-13 17:48:21
 * @LastEditTime: 2021-06-29 15:46:29
 * @Description: 博客api
 */

import request from "./request";

/**
 * 分页请求博客
 * @param {*} page 
 * @param {*} size 
 * @returns 
 */
export function getBlog(page, size, data = {}) {
  return request({
    url: `/api/blog/${page}/${size}`,
    method: 'get',
    params: data
  })
}

/**
 * 添加一篇文章
 * @param {*} data 
 * @returns 
 */
export function addBlog(data) {
  return request({
    url: `/api/blog`,
    method: 'post',
    data
  })
}

/**
 * 修改博客
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export function editBlog(id, data) {
  return request({
    url: `/api/blog/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除文章
 * @param {*} id 
 * @returns 
 */
export function delBlog(id) {
  return request({
    url: `/api/blog/${id}`,
    method: 'delete'
  })
}

/**
 * 根据博客id得到博客详情
 * @param {*} id 
 * @returns 
 */
export function getBlogDetail(id) {
  return request({
    url: `/api/blog/${id}`,
    method: 'get'
  })
}