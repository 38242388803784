import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

//导入富文本框
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor);

//导入事件总线
import './EventBus';

// import 'lib-flexible';
import './styles/index.less';
import utils from '@/utils/utils';

//导入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import { showMessage } from './utils/showMessage';

//导入mock
// import './mock';

//导入自定义指令
import loading from './directives/loading';
import imglazy from './directives/imglazy';

Vue.config.productionTip = false;

//注册自定义指令
Vue.directive('loading', loading);
Vue.directive('imglazy', imglazy);

//弹框脚本
Vue.prototype.$showMessage = showMessage;

//工具方法
Vue.prototype.$utils = utils;

//先执行一次whoami
store.dispatch('loginUser/whoAmI');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
