/*
 * @Author: CL
 * @Date: 2021-06-11 16:59:24
 * @LastEditTime: 2021-06-23 10:39:45
 * @Description: 弹框脚本
 */

import { getComponentRootDom } from '@/utils';
import IconFont from '../components/IconFont';
import style from '../styles/showMessage.module.less';

/**
 * 1. message:String,  描述性文字
 * 2. type:String,  弹框类型info, success, error, warning
 * 3. duration:Number, 停留时间
 * 4. dom:,  dom元素，在哪一个dom元素中居中展示
 * 5. callback:Function, 动画结束之后的回调函数
 * @param {*} options 
 */
export function showMessage(options = {}) {
  const { message, type = 'info', duration = 1000, dom, callback } = options;

  //创建一个div元素
  const div = document.createElement('div');
  div.className = `${style['message']} ${style[type]}`;

  //要设置这个元素的内容
  const iconDom = getComponentRootDom(IconFont, { type, size: 20 });
  div.innerHTML = `
    <span>${iconDom.outerHTML}</span>
    <div>${ message }</div>
  `
  
  //要把这个元素加入到页面中
  if (!dom) {
    //如果没有传入dom元素，就加入到body中
    document.body.appendChild(div);
  } else {
    //如果传入了dom元素，判断这个元素有没有绝对或者相对定位
    if (getComputedStyle(dom).position === 'static') {
      dom.style.position = 'relative';
    }

    dom.appendChild(div);
  }

  div.addEventListener('webkitAnimationEnd', () => {
    div.remove();
    callback && callback();
  }, {
    once: true
  })
}

