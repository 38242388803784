/*
 * @Author: CL
 * @Date: 2021-06-22 13:53:31
 * @LastEditTime: 2021-07-02 11:14:44
 * @Description: 登录仓库
 */

import { login, whoAmI, verify } from '@/api/login.js';
import { delay } from '@/utils';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    loading: false,    //是否在登录
    user: null,
    verifyCode: {},    //验证码对象，文本和图片
  },

  getters: {
    /**
     * 登录的状态
     */
    status(state) {
      if (state.loading) {
        //正在登录状态
        return 'loading';
      } else if (state.user) {
        //登录状态
        return 'login';
      } else {
        //未登录的状态
        return 'unlogin';
      }
    }
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },

    setUser(state, payload) {
      state.user = payload;
    },

    setVerifyCode(state, payload) {
      state.verifyCode = payload;
    }
  },

  actions: {
    /**
     * 登录方法
     */
    async login({ commit, state }, payload) {
      commit('setLoading', true);
      const res = await login(payload);
      commit('setLoading', false);
      if (res.code == 200) {
        commit('setUser', { id: res.data.id, loginId: res.data.loginId });
        if (res.data.token) {
          //如果token有值就保存到缓存中
          localStorage.setItem('token', res.data.token)
        }
        localStorage.setItem('user', JSON.stringify(state.user));
      }

      return res;
    },

    /**
     * 判断之前有没有登录过
     */
    async whoAmI({ commit }) {
      try {
        commit('setLoading', true);
        const res = await whoAmI();
        commit('setLoading', false);
        if (res.code == 200) {
          if (res.data) {
            localStorage.setItem('user', JSON.stringify(res.data));
          }
          commit('setUser', res.data);
        }
      } catch (err) {
        console.log(err);
      }
    },

    /**
     * 退出登录 
     */
    async logOut({ commit }) {
      commit('setLoading', true);
      await delay(500);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      commit('setUser', null);
      commit('setLoading', false);
      //退出成功跳转到首页，如果在首页就不用跳转
      if (router.history.current.name == 'home') return;
      router.push({ name: 'home' });
    },

    /**
     * 请求验证码
     */
    async getVerifyCode({ commit }) {
      try {
        const res = await verify();
        if (res.code == 200) {
          commit('setVerifyCode', res.data);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
}
