/*
 * @Author: CL
 * @Date: 2021-06-22 13:59:28
 * @LastEditTime: 2021-07-01 23:16:25
 * @Description: 登录相关的api
 */

import request from './request';

/**
 * 登录的方法
 */
export function login(data) {
  return request({
    url: '/api/admin/login',
    method: 'post',
    data
  })
}

/**
 * 判断之前有没有登录过
 * @returns 
 */
export function whoAmI() {
  return request({
    url: '/api/admin/whoami',
    method: 'get',
  })
}

/**
 * 得到验证码
 * @returns 
 */
export function verify() {
  return request({
    url: '/api/verifycode',
    method: 'get'
  })
}
