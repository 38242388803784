/*
 * @Author: CL
 * @Date: 2021-06-25 22:10:55
 * @LastEditTime: 2021-07-12 22:27:56
 * @Description: 每日一句的仓库
 */

import { queryWord } from '@/api/everyday.js';

export default {
  namespaced: true,
  state: {
    word: ''
  },

  mutations: {
    setWord(state, payload) {
      state.word = payload;
    }
  },

  actions: {
    /**
     * 查询最新的每日一句
     */
    async queryWords({ commit, state }) {
      // if (state.word) return;
      const res = await queryWord();
      if (res.code == 200 && res.data.length > 0) {
        commit('setWord', res.data[0].content);
      }
    }
  }
}
