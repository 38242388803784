<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  watch: {
    $route: {
      handler(val){
        const { path } = val;
        const isDetail = this.isDetail(path);
        if(isDetail){
          //如果是跳转到文章详情页，先要判断这个文章存不存在，存在就跳转，不存在
        }
      }
    }
  },

  methods: {
    /**
     * 判断路径是否是文章详情页
     */
    isDetail(path){
      const reg = /^\/detail\/\d+$/;

      return reg.test(path);
    }
  }
}
</script>

<style lang="less">
@import '~@/styles/mixin.less';

#app {
  .self-fill(fixed);
  min-width: 900px;
  min-height: 600px;
}
</style>
