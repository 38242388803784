/*
 * @Author: CL
 * @Date: 2021-06-21 23:20:17
 * @LastEditTime: 2021-06-23 23:06:36
 * @Description: 工具方法
 */

import Vue from 'vue';

/**
 * 延迟函数
 * @param {*} timeout 
 * @returns 
 */
export function delay(timeout) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  })
}

/**
 * 获取某个组件渲染的根元素
 */
export function getComponentRootDom(component, props) {
  const vm = new Vue({
    render: h => h(component, { props })
  })

  vm.$mount();

  return vm.$el;
}


