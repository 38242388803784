/*
 * @Author: CL
 * @Date: 2021-05-13 17:42:20
 * @LastEditTime: 2021-07-21 22:50:19
 * @Description: 请求接口
 */

const axios = require('axios');
import router from '@/router';
import { showMessage } from '@/utils/showMessage';
import Vue from 'vue';
const vue = new Vue();

let request = axios.create({
  // baseURL: "http://localhost:9528",
  // baseURL: "http://www.clei.top:9528",
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 3000,
  withCredentials:true
});

/**
 * axios响应拦截
 */
request.interceptors.request.use(config => {
  config.headers = {
    Authorization: localStorage.getItem('token')
  }

  return config;
})

/**
 * axios响应拦截器
 */
request.interceptors.response.use(response => {
  return response.data;
}, error => {
  const err = error.response;
  if (err) {
    if (err.status == 401) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      
      console.log(router);
      //token无效或者token被篡改
      if (router.history['current']['name'] != 'home' && router.history['_startLocation'] !== '/login') {
        //当前页不是首页
        showMessage({
          type: 'warning',
          message: '登录状态过期，请重新登录'
        })
        router.push({ name: 'home' });
      }
    }
  }
  return Promise.resolve(error.response.data);
})

export default request;

