/*
 * @Author: CL
 * @Date: 2021-06-22 09:42:38
 * @LastEditTime: 2021-06-27 18:04:00
 * @Description: 导航相关的api
 */

import request from './request';

/**
 * 查询所有的导航数据
 */
export function queryNav() {
  return request({
    url: '/api/nav',
    method: 'get'
  })
}

/**
 * 分页查询导航数据
 * @param {*} page 
 * @param {*} size 
 * @param {*} data 
 * @returns 
 */
export function queryNavByPage(page, size, data) {
  return request({
    url: `/api/nav/${page}/${size}`,
    method: 'get',
    params: data
  })
}

/**
 * 添加导航信息
 */
export function addNav(data) {
  return request({
    url: `/api/nav`,
    method: 'post',
    data
  })
}

/**
 * 修改导航
 * @param {*} id 
 * @param {*} data 
 * @returns 
 */
export function editNav(id, data) {
  return request({
    url: `/api/nav/${id}`,
    method: 'put',
    data
  })
}

/**
 * 删除导航
 * @param {*} id 
 */
export function delNav(id) {
  return request({
    url: `/api/nav/${id}`,
    method: 'delete'
  })
}
